import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import tmdbLogo from './logo.svg';
import { app, analytics } from './firebase'; // Import Firebase
import { logEvent } from "firebase/analytics"; // Import logEvent


const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;
const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;

function App() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [expandedSeasons, setExpandedSeasons] = useState({});
  const [expandedEpisodes, setExpandedEpisodes] = useState({});
  const [cast, setCast] = useState([]);
  const [expandedCast, setExpandedCast] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [watchProviders, setWatchProviders] = useState(null);
  const [videoKey, setVideoKey] = useState('');
  const [activeTab, setActiveTab] = useState('Season/Episode List');
  const [chatMessages, setChatMessages] = useState([{ role: "system", content: "Hey there, anything in particular you'd like to recap?" }]);
  const [userMessage, setUserMessage] = useState('');
  const chatEndRef = useRef(null);

  const apiKey = TMDB_API_KEY;
  const baseUrl = 'https://api.themoviedb.org/3';
  const imageBaseUrl = 'https://image.tmdb.org/t/p/w300';
  const fullImageBaseUrl = 'https://image.tmdb.org/t/p/original';

  const [isAutoScrollActive, setIsAutoScrollActive] = useState(true);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);

  const toggleAboutPane = () => {
    setIsAboutVisible(!isAboutVisible);
  };

  const togglePrivacyPolicyPane = () => {
    setIsPrivacyPolicyVisible(!isPrivacyPolicyVisible);
  };

  const scrollToBottom = () => {
    if (chatEndRef.current && isAutoScrollActive) {
      const chatMessagesContainer = document.querySelector(".chat-messages");
      if (chatMessagesContainer) {
        chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    const chatBox = document.querySelector(".chat-messages");

    if (!chatBox) return; // Safety check

    const handleUserScroll = () => {
      const isScrolledToBottom = chatBox.scrollHeight - chatBox.clientHeight <= chatBox.scrollTop + 10;
      setIsAutoScrollActive(isScrolledToBottom);

      // Log the scroll event to Firebase Analytics
      logEvent(analytics, 'user_scroll', {
        autoScrollActive: isScrolledToBottom,
        scrollTop: chatBox.scrollTop,
        scrollHeight: chatBox.scrollHeight,
        clientHeight: chatBox.clientHeight
      });
      console.log('User scroll event logged');
    };

    // Apply initial scroll when the component mounts or updates
    scrollToBottom();

    // Log initial load event
    logEvent(analytics, 'component_loaded', {
      component: 'chatBox',
      autoScrollActive: isAutoScrollActive
    });
    console.log('Component loaded event logged');

    // Set up the event listener
    chatBox.addEventListener('scroll', handleUserScroll);

    // Cleanup the event listener when the component unmounts or updates
    return () => chatBox.removeEventListener('scroll', handleUserScroll);
  }, [chatMessages, isAutoScrollActive]);


  const handleSearch = async (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/search/tv`, {
          params: {
            api_key: apiKey,
            query: value
          }
        });
        setResults(response.data.results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setResults([]);
    }
  };

  const handleSelectShow = async (show) => {
    setResults([]);
    setSelectedShow(show);
    setBackgroundImage(show.backdrop_path ? `${fullImageBaseUrl}${show.backdrop_path}` : '');

    try {
      const detailsResponse = await axios.get(`${baseUrl}/tv/${show.id}`, {
        params: { api_key: apiKey }
      });
      setSeasons(detailsResponse.data.seasons);

      const castResponse = await axios.get(`${baseUrl}/tv/${show.id}/credits`, {
        params: { api_key: apiKey }
      });
      setCast(castResponse.data.cast);

      const watchResponse = await axios.get(`${baseUrl}/tv/${show.id}/watch/providers`, {
        params: { api_key: apiKey }
      });
      const provider = watchResponse.data.results.US?.flatrate[0];
      setWatchProviders(provider ? {
        ...provider,
        watchUrl: provider.provider_id ? `https://www.justwatch.com/us/provider/${provider.provider_name.toLowerCase().replace(/\s/g, '-')}/tv-shows` : `https://www.google.com/search?q=Watch+${encodeURIComponent(show.name)}`
      } : null);

      const videoResponse = await axios.get(`${baseUrl}/tv/${show.id}/videos`, {
        params: { api_key: apiKey }
      });
      const trailer = videoResponse.data.results.find(video => video.site === "YouTube" && video.type === "Trailer");
      setVideoKey(trailer ? trailer.key : '');

      setExpandedSeasons({});
      setExpandedEpisodes({});
      setExpandedCast(false);
    } catch (error) {
      console.error('Error fetching show details:', error);
    }
  };

  const toggleSeason = (seasonId) => {
    setExpandedSeasons((prev) => ({
      ...prev,
      [seasonId]: !prev[seasonId]
    }));
  };

  const toggleEpisode = (episodeId) => {
    setExpandedEpisodes((prev) => ({
      ...prev,
      [episodeId]: !prev[episodeId]
    }));
  };

  const fetchEpisodes = async (season) => {
    try {
      const response = await axios.get(`${baseUrl}/tv/${selectedShow.id}/season/${season.season_number}`, {
        params: { api_key: apiKey }
      });
      return response.data.episodes;
    } catch (error) {
      console.error('Error fetching episodes:', error);
      return [];
    }
  };

  const handleSeasonClick = async (season) => {
    if (!expandedSeasons[season.id]) {
      const episodes = await fetchEpisodes(season);
      setSeasons((prev) =>
        prev.map((s) =>
          s.id === season.id ? { ...s, episodes } : s
        )
      );
    }
    toggleSeason(season.id);
  };

  const toggleCast = () => {
    setExpandedCast(!expandedCast);
  };

  const sendMessageToChat = async () => {
    if (!userMessage.trim()) return;

    const formattedMessage = userMessage.trim();
    const showContext = selectedShow ? `I am talking about the show ${selectedShow.name}. Remember to respond naturally. Don't repeat the name of the show, as that's already a given. ` : '';

    // Add user message to chat
    setChatMessages(prev => [...prev, { role: "user", content: formattedMessage }]);

    // Clear the user message input
    setUserMessage('');

    // Ensure automatic scrolling is active
    setIsAutoScrollActive(true);

    try {
      const response = await axios.post('https://api.groq.com/openai/v1/chat/completions', {
        messages: [
          { role: "system", content: "You are a chat-bot on a recap website. Do not speak about any other topic except for TV Shows, Movies, and Anime." },
          { role: "user", content: `${formattedMessage} ${showContext}` }
        ],
        model: "llama-3.3-70b-versatile"
      }, {
        headers: {
          'Authorization': `Bearer ${GROQ_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      const botMessage = response.data.choices[0]?.message?.content || "Sorry, I couldn't get that.";
      let displayedMessage = '';

      // Display animated typing effect for the bot's message
      for (let i = 0; i < botMessage.length; i++) {
        displayedMessage += botMessage[i];
        // Update the message for each character
        await new Promise(resolve => setTimeout(resolve, 10)); // Control the typing speed
        setChatMessages(prev => {
          // Here we make sure to append the bot's message without replacing the user's message
          if (i === 0) {
            return [...prev, { role: "system", content: displayedMessage }];
          } else {
            let newMessages = [...prev];
            newMessages[newMessages.length - 1] = { role: "system", content: displayedMessage };
            return newMessages;
          }
        });
      }

      // Ensure the chat box scrolls to the bottom to show new messages
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message to Groq API:', error);
      let errorMessage = "Error processing your request.";
      if (error.response && error.response.data) {
        errorMessage = error.response.data.error.message || JSON.stringify(error.response.data.error);
      } else {
        errorMessage = error.message;
      }
      setChatMessages(prev => [...prev, { role: "system", content: errorMessage }]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessageToChat();
    }
  };

  return (
    <Router>
      <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed' }}>
        <div className="top-left-pane">
          <h2>Recap Anime and TV Shows!</h2>
        </div>
        <div className="content">
          <div className="title-pane">
            <h1 className="title">Recapper</h1>
          </div>
          <div className="search-container">
            <input
              type="text"
              value={query}
              onChange={handleSearch}
              placeholder="Search for a show or anime..."
              className="search-input"
            />
            {results.length > 0 && (
              <div className="dropdown">
                {loading ? <p>Loading...</p> : results.map((item, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleSelectShow(item)}
                  >
                    <img
                      src={item.poster_path ? `${imageBaseUrl}${item.poster_path}` : ''}
                      alt={item.name}
                      className="dropdown-image"
                    />
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {selectedShow && (
            <div className="details-container">
              <div className="text-pane">
                <div className="left-pane">
                  <h2>{selectedShow.name}</h2>
                  <img
                    src={selectedShow.poster_path ? `${imageBaseUrl}${selectedShow.poster_path}` : ''}
                    alt={selectedShow.name}
                    className="show-image"
                  />
                  <p>{selectedShow.overview}</p>
                  {watchProviders && (
                    <a href={watchProviders.watchUrl} className="watch-now" target="_blank" rel="noopener noreferrer">
                      <img src={`${imageBaseUrl}${watchProviders.logo_path}`} alt="Watch Now" className="streaming-logo" />
                      <span>Watch on {watchProviders.provider_name}</span>
                    </a>
                  )}
                </div>
                <div className="cast-pane">
                  <h3 onClick={toggleCast}>Cast: {expandedCast ? '▼' : '►'}</h3>
                  {expandedCast && (
                    <div className="cast-list">
                      {cast.map((actor, index) => (
                        <div key={index} className="cast-member">
                          <img src={actor.profile_path ? `${imageBaseUrl}${actor.profile_path}` : 'https://via.placeholder.com/50x75'} alt={actor.name} className="cast-image" />
                          <p>{actor.name} as {actor.character}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {videoKey && (
                  <div className="video-preview">
                    <iframe
                      src={`https://www.youtube.com/embed/${videoKey}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Show Trailer"
                      className="video-iframe"
                    ></iframe>
                  </div>
                )}
              </div>
              <div className="right-pane">
                <div className="tabs">
                  <button
                    className={`tab-button ${activeTab === 'Season/Episode List' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Season/Episode List')}
                  >
                    Season/Episode List
                  </button>
                  <button
                    className={`tab-button ${activeTab === 'Ask about it (AI)' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Ask about it (AI)')}
                  >
                    Ask about it (AI)
                  </button>
                </div>
                {activeTab === 'Season/Episode List' && (
                  <div className="seasons">
                    {seasons.map((season) => (
                      <div key={season.id} className="season">
                        <h3>
                          <span
                            className="indicator"
                            onClick={() => handleSeasonClick(season)}
                            style={{ cursor: 'pointer', width: '20px' }}
                          >
                            {expandedSeasons[season.id] ? '-' : '+'}
                          </span>
                          {season.name} ({season.episode_count} episodes)
                        </h3>
                        {expandedSeasons[season.id] && (
                          <div className="episodes">
                            {season.episodes.map((episode) => (
                              <div key={episode.id} className="episode" onClick={(e) => { e.stopPropagation(); toggleEpisode(episode.id); }}>
                                <h4>
                                  <span className="indicator">{expandedEpisodes[episode.id] ? '-' : '+'}</span>
                                  {episode.episode_number}. {episode.name}
                                </h4>
                                <span>{episode.air_date} | {episode.runtime} mins</span>
                                {expandedEpisodes[episode.id] && <p>{episode.overview}</p>}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === 'Ask about it (AI)' && (
                  <div className="chatbox">
                    <div className="chat-messages">
                      {chatMessages.map((message, index) => (
                        <div
                          key={index}
                          className={`chat-message ${message.role === 'user' ? 'user-message' : 'system-message'}`}
                        >
                          <span>{message.content}</span>
                        </div>
                      ))}
                      <div ref={chatEndRef} />
                    </div>
                    <div className="chat-input-container">
                      <input
                        type="text"
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Type your question..."
                        className="chat-input"
                      />
                      <button onClick={sendMessageToChat} className="send-button">Ask</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="buttons-container">
            <button onClick={togglePrivacyPolicyPane} className="privacy-policy-button">Privacy Policy</button>
            <button onClick={toggleAboutPane} className="about-button">About</button>
          </div>
        </div>
        {isAboutVisible && (
          <div className="about-pane">
            <h2>About</h2>
            <p>This product uses the TMDB API but is not endorsed or certified by TMDB.</p>
            <img src={tmdbLogo} alt="TMDB Logo" className="tmdb-logo" />
            <button onClick={toggleAboutPane} className="close-about">Close</button>
          </div>
        )}
        {isPrivacyPolicyVisible && (
          <div className="privacy-policy-pane">
            <div className="privacy-policy">
              <h2>Privacy Policy</h2>
              <p>Effective Date: 5/13/2024</p>
              <p>
                Welcome to Recapper.net. This privacy policy ("Policy") explains how we collect, use, and share your personal information when you visit our website and use our services. By using our website, you agree to the collection and use of information in accordance with this policy.
              </p>
              <h3>1. Introduction</h3>
              <p>
                We collect the following information: Personal Information, Usage Data, Cookies and Tracking Technologies. Personal Information includes data you provide to us like name and email address. Usage Data includes information such as your IP address, browser type, and pages visited.
              </p>
              <h3>2. Information We Collect</h3>
              <p>
                <strong>a. Personal Information</strong>
                <br />
                We may collect personal information that you provide to us, such as your name, email address, and any other information you choose to provide.
              </p>
              <p>
                <strong>b. Usage Data</strong>
                <br />
                We collect information on how the service is accessed and used ("Usage Data"). This Usage Data may include information such as your device's Internet Protocol ("IP") address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
              </p>
              <p>
                <strong>c. Cookies and Tracking Technologies</strong>
                <br />
                We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
              </p>
              <h3>3. How We Use Your Information</h3>
              <p>
                We use the collected data for various purposes:
                <ul>
                  <li>To provide and maintain our service</li>
                  <li>To notify you about changes to our service</li>
                  <li>To allow you to participate in interactive features of our service when you choose to do so</li>
                  <li>To provide customer support</li>
                  <li>To gather analysis or valuable information so that we can improve our service</li>
                  <li>To monitor the usage of our service</li>
                  <li>To detect, prevent, and address technical issues</li>
                  <li>To provide you with news, special offers, and general information about other goods, services, and events that we offer</li>
                </ul>
              </p>
              <h3>4. Sharing of Your Information</h3>
              <p>
                We will not share your personal information with third parties except in the following circumstances:
                <ul>
                  <li>With your consent</li>
                  <li>To comply with legal obligations</li>
                  <li>To protect and defend the rights or property of Recapper.net</li>
                  <li>To prevent or investigate possible wrongdoing in connection with the service</li>
                  <li>To protect the personal safety of users of the service or the public</li>
                  <li>To protect against legal liability</li>
                </ul>
              </p>
              <h3>5. Security of Your Information</h3>
              <p>
                The security of your personal information is important to us, but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
              </p>
              <h3>6. Links to Other Sites</h3>
              <p>
                Our service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
              </p>
              <h3>7. Children's Privacy</h3>
              <p>
                Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.
              </p>
              <h3>8. Changes to This Privacy Policy</h3>
              <p>
                We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.
              </p>
              <h3>9. Contact Us</h3>
              <p>
                If you have any questions about this privacy policy, please contact me by email: fbader1994@gmail.com
              </p>
            </div>
            <button onClick={togglePrivacyPolicyPane} className="close-privacy-policy">Close</button>
          </div>
        )}
      </div>
      <Routes>
        <Route path="/privacy-policy" element={<div className="privacy-policy">
          <h2>Privacy Policy</h2>
          <p>Effective Date: 5/13/2024</p>
          <p>
            Welcome to Recapper.net. This privacy policy ("Policy") explains how we collect, use, and share your personal information when you visit our website and use our services. By using our website, you agree to the collection and use of information in accordance with this policy.
          </p>
          <h3>1. Introduction</h3>
          <p>
            We collect the following information: Personal Information, Usage Data, Cookies and Tracking Technologies. Personal Information includes data you provide to us like name and email address. Usage Data includes information such as your IP address, browser type, and pages visited.
          </p>
          <h3>2. Information We Collect</h3>
          <p>
            <strong>a. Personal Information</strong>
            <br />
            We may collect personal information that you provide to us, such as your name, email address, and any other information you choose to provide.
          </p>
          <p>
            <strong>b. Usage Data</strong>
            <br />
            We collect information on how the service is accessed and used ("Usage Data"). This Usage Data may include information such as your device's Internet Protocol ("IP") address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
          </p>
          <p>
            <strong>c. Cookies and Tracking Technologies</strong>
            <br />
            We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          </p>
          <h3>3. How We Use Your Information</h3>
          <p>
            We use the collected data for various purposes:
            <ul>
              <li>To provide and maintain our service</li>
              <li>To notify you about changes to our service</li>
              <li>To allow you to participate in interactive features of our service when you choose to do so</li>
              <li>To provide customer support</li>
              <li>To gather analysis or valuable information so that we can improve our service</li>
              <li>To monitor the usage of our service</li>
              <li>To detect, prevent, and address technical issues</li>
              <li>To provide you with news, special offers, and general information about other goods, services, and events that we offer</li>
            </ul>
          </p>
          <h3>4. Sharing of Your Information</h3>
          <p>
            We will not share your personal information with third parties except in the following circumstances:
            <ul>
              <li>With your consent</li>
              <li>To comply with legal obligations</li>
              <li>To protect and defend the rights or property of Recapper.net</li>
              <li>To prevent or investigate possible wrongdoing in connection with the service</li>
              <li>To protect the personal safety of users of the service or the public</li>
              <li>To protect against legal liability</li>
            </ul>
          </p>
          <h3>5. Security of Your Information</h3>
          <p>
            The security of your personal information is important to us, but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
          </p>
          <h3>6. Links to Other Sites</h3>
          <p>
            Our service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
          </p>
          <h3>7. Children's Privacy</h3>
          <p>
            Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.
          </p>
          <h3>8. Changes to This Privacy Policy</h3>
          <p>
            We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.
          </p>
          <h3>9. Contact Us</h3>
          <p>
            If you have any questions about this privacy policy, please contact me by email: fbader1994@gmail.com
          </p>
        </div>} />
      </Routes>
    </Router>
  );
}

export default App;
